import React from 'react';

const Conservation = () => {
  return (
    <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0 32C6.90252 31.1613 9.09274 29.7356 9.09274 29.7356C9.09274 29.7356 12.7241 30.332 16.1849 29.7356C28.4824 27.7135 29.6391 17.463 28.3876 12.0489C27.136 6.62552 32 5.3116 32 5.3116L28.4634 1.27666C27.7239 0.512533 26.6809 0.0186479 25.5147 0.0186479C18.6121 -0.39137 16.0047 7.99535 16.0047 7.99535C16.0047 7.99535 20.9161 8.83402 22.6702 13.5399C25.4578 20.9295 19.0293 24.0326 19.0293 24.0326V24.014C21.0394 22.5603 22.3668 20.2306 22.3668 17.5935C22.3668 13.1765 18.7259 9.60747 14.2507 9.60747C12.6388 9.60747 11.1502 10.0827 9.88918 10.8748H9.87022C9.81333 10.8934 9.77541 10.94 9.71852 10.9587C9.71852 10.968 9.69956 10.968 9.69956 10.968C2.07644 15.1147 0.303407 30.1083 0 32ZM25.5716 2.93536C25.5716 2.50671 25.9224 2.1526 26.3585 2.1526C26.7947 2.1526 27.1455 2.49739 27.1455 2.93536C27.1455 3.37334 26.7947 3.69949 26.3585 3.69949C25.9224 3.69949 25.5716 3.3547 25.5716 2.93536Z"
        fill="white"
      />
    </svg>
  );
};

export default Conservation;
