import React from 'react';

const TreePlanting = () => {
  return (
    <svg viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.5401 17.4327C17.4234 17.5429 17.3326 17.6207 17.2484 17.6984C12.3981 22.5078 15.8153 19.0596 10.9586 23.8625C10.7381 24.0829 10.6798 24.2514 10.7965 24.556C11.4838 26.3774 11.2569 28.1339 10.2713 29.7673C9.7979 30.5516 9.16892 31.2451 8.59182 31.9646C8.3519 32.2692 8.00175 32.3664 7.64511 32.2044C7.19769 32.0035 6.73731 31.7961 6.34177 31.5044C4.0982 29.8386 2.11401 27.9006 0.538322 25.5801C-0.252762 24.4134 -0.194404 24.0894 0.856052 23.1754C2.08158 22.106 3.44329 21.3152 5.09678 21.1337C6.02404 21.03 6.91887 21.1726 7.78128 21.5032C8.01472 21.5939 8.16385 21.5745 8.34541 21.3865C13.1892 16.4928 9.77196 19.8698 14.6222 14.9826C14.6935 14.9113 14.7584 14.8206 14.8103 14.7622C13.883 13.6733 12.9557 12.6038 12.0609 11.5214C11.7172 11.1066 11.4319 10.6529 11.1142 10.2186C10.9845 10.0436 10.9716 9.91395 11.1466 9.73894C13.4032 7.49628 15.6208 5.21474 17.9162 3.01097C20.4321 0.599789 23.4927 -0.139122 26.8775 0.502563C28.0577 0.72294 29.1924 1.15721 30.3401 1.5137C30.4958 1.55908 30.6708 1.71464 30.7357 1.86371C31.4554 3.54247 31.9223 5.28604 31.9872 7.11387C32.0909 9.98524 31.1507 12.4677 29.1341 14.5159C26.9618 16.7262 24.7507 18.904 22.5655 21.1013C22.3774 21.2893 22.2348 21.3217 22.0273 21.1532C20.6915 20.0578 19.3493 18.9688 18.007 17.8734C17.8514 17.7438 17.7152 17.6012 17.5337 17.4327H17.5401ZM7.98878 29.8451C9.24025 28.6589 9.78493 27.2978 9.30509 25.6644C8.83822 24.0764 7.72941 23.1236 6.10185 22.8643C4.6364 22.631 3.44977 23.2014 2.45119 24.3033C4.56507 25.9042 6.38716 27.7321 7.99526 29.8451H7.98878Z"
        fill="white"
      />
    </svg>
  );
};

export default TreePlanting;
