import React from 'react';

const NaturalRegeneration = () => {
  return (
    <svg viewBox="0 0 34 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20.2814 15.9309C22.2267 16.5873 24.8364 17.6141 28.3508 13.9532C32.7698 9.30761 33.4662 3.67739 33.5703 1.52292C33.6023 1.16104 33.2901 0.824405 32.9459 0.866485C30.8245 0.975891 24.8444 1.30411 20.3214 6.09274C16.8471 9.74524 17.4315 12.0175 18.128 13.0779C20.5616 10.2249 23.9398 7.63285 28.1106 5.98334C26.7177 6.85859 23.4835 9.24028 21.0179 11.8745C19.0326 13.9953 17.2954 16.3012 16.1827 18.7502C15.5182 17.0334 14.3414 15.022 12.4922 12.9349C10.1626 10.267 7.03253 7.818 5.67162 6.90067C9.73834 8.65959 12.9725 11.3274 15.3021 14.2141C16.0305 13.1873 16.655 10.9992 13.3568 7.26255C9.11392 2.36451 3.23799 1.89322 1.18861 1.67441C0.836379 1.64075 0.52417 1.92689 0.52417 2.29718C0.556191 4.41799 1.04452 9.97246 5.28736 14.6938C8.24134 18.018 10.6429 17.6562 12.4842 17.1091C13.1806 17.9844 14.7417 20.2567 14.7097 23.2864C11.2353 23.6903 8.27336 25.1884 6.64026 27.3092C6.29603 27.7468 6.60824 28.4032 7.12859 28.4032H25.8051C26.3655 28.4032 26.6376 27.7468 26.2934 27.3092C24.6203 25.1547 21.5302 23.5809 17.9118 23.2527C17.8478 19.3309 19.4488 17.2522 20.2814 15.9309Z"
        fill="white"
      />
    </svg>
  );
};

export default NaturalRegeneration;
