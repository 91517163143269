const themeProperties = {
  defaultFontFamily:
    '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
  fontSizes: {
    fontXXSmall: '10px',
    fontXSmall: '12px',
    fontSmall: '14px',
    fontSixteen: '16px',
    fontMedium: '18px',
    fontLarge: '22px',
    fontXLarge: '26px',
    fontXXLarge: '36px',
    fontXXXLarge: '48px',
    //to be converted to same unit
    fontRemLarge: '2.25rem',
    fontRemMedium: '1.5rem',
    fontRemSmall: '1.125rem',
    fontRemXSmall: '0.875rem',
  },
  primaryColor: '#68B030',
  primaryDarkColor: '#007a49',
  primaryLightColor: '#79AE4633',
  topProjectBackgroundColor: '#F3BB44',
  nonDonatableProjectBackgroundColor: '#828282',
  light: {
    primaryFontColor: '#2f3336',
    dividerColor: '#d5d5d5',
    secondaryColor: '#ecb641',
    backgroundColor: '#fff',
    highlightBackground: '#4d5153',
    light: '#fff',
    dark: '#000',
    dangerColor: '#e74c3c',
    safeColor: '#2980b9',
    backgroundColorDark: '#F2F2F7',
    unDecadeFill: '#1d1d1b',
    mapboxIcon: 'invert(0) hue-rotate(180deg)',
    backgroundBase: '#FaFaFf',
    disabledFontColor: '#909090',
    tabBackgroundColor: '#f4ffec',
  },
  dark: {
    primaryFontColor: '#ffffff',
    dividerColor: '#101113',
    secondaryColor: '#ecb641',
    backgroundColor: '#343a40',
    highlightBackground: '#f2f2f2',
    light: '#fff',
    dark: '#000',
    dangerColor: '#00ef00',
    safeColor: '#2980b9',
    backgroundColorDark: '#101113',
    unDecadeFill: '#fff',
    mapboxIcon: 'invert(0.8) hue-rotate(180deg)',
    backgroundBase: '#202020',
    disabledFontColor: '#686868',
  },
  fontWeight: '700',
};

export default themeProperties;
