import React from 'react';

function PartnerIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 255.12 255.12">
      <g id="Ebene_1" data-name="Ebene 1">
        <path
          fill="var(--primary-font-color)"
          d="M214.37,219v7.75H40.75V219a4.65,4.65,0,0,1,4.65-4.65h7.75V37.65a9.3,9.3,0,0,1,9.3-9.3H192.67a9.3,9.3,0,0,1,9.3,9.3V214.37h7.75A4.65,4.65,0,0,1,214.37,219ZM90.35,73.3A4.65,4.65,0,0,0,95,78h15.51a4.65,4.65,0,0,0,4.65-4.65V57.8a4.65,4.65,0,0,0-4.65-4.65H95a4.65,4.65,0,0,0-4.65,4.65Zm0,37.21A4.66,4.66,0,0,0,95,115.16h15.51a4.66,4.66,0,0,0,4.65-4.65V95a4.66,4.66,0,0,0-4.65-4.65H95A4.66,4.66,0,0,0,90.35,95Zm24.81,37.2v-15.5a4.65,4.65,0,0,0-4.65-4.65H95a4.65,4.65,0,0,0-4.65,4.65v15.5A4.65,4.65,0,0,0,95,152.36h15.51A4.65,4.65,0,0,0,115.16,147.71ZM140,181.82a4.65,4.65,0,0,0-4.65-4.65h-15.5a4.65,4.65,0,0,0-4.65,4.65v32.55H140Zm24.8-124a4.65,4.65,0,0,0-4.65-4.65h-15.5A4.65,4.65,0,0,0,140,57.8V73.3A4.65,4.65,0,0,0,144.61,78h15.5a4.65,4.65,0,0,0,4.65-4.65Zm0,37.2a4.65,4.65,0,0,0-4.65-4.65h-15.5A4.65,4.65,0,0,0,140,95v15.51a4.65,4.65,0,0,0,4.65,4.65h15.5a4.65,4.65,0,0,0,4.65-4.65Zm0,37.21a4.65,4.65,0,0,0-4.65-4.65h-15.5a4.65,4.65,0,0,0-4.65,4.65v15.5a4.65,4.65,0,0,0,4.65,4.65h15.5a4.65,4.65,0,0,0,4.65-4.65Z"
        ></path>
      </g>
    </svg>
  );
}

export default PartnerIcon;
