interface Props {
  color?: string;
}
export default function ProjectInfo({ color }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
    >
      <g clipPath="url(#clip0_594_92)">
        <path
          d="M7 0.868225C3.13444 0.868225 0 4.00267 0 7.86823C0 11.7338 3.13444 14.8682 7 14.8682C10.8656 14.8682 14 11.7338 14 7.86823C14 4.00267 10.8656 0.868225 7 0.868225ZM8.23978 10.8067L8.15422 11.0929C8.14022 11.1427 8.11067 11.1862 8.07022 11.2189C7.588 11.5891 6.97044 11.7338 6.37467 11.6171L6.37933 11.6109C6.36378 11.6078 6.34822 11.6062 6.33267 11.6016C5.81933 11.474 5.50667 10.956 5.63422 10.4427L6.3 7.76245C6.45867 7.12 5.754 7.27245 5.46311 7.36111C5.42733 7.372 5.39 7.35178 5.37911 7.316C5.376 7.30356 5.376 7.28956 5.37911 7.27711L5.46467 6.99089C5.47867 6.94111 5.50822 6.89756 5.54867 6.86489C6.03089 6.49467 6.64844 6.35 7.24422 6.46667C7.24422 6.46667 7.28156 6.47289 7.30022 6.47756C7.81356 6.60511 8.12622 7.12311 7.99867 7.63645L7.29867 10.4551C7.26756 10.9404 7.88667 10.8051 8.15733 10.7227C8.19311 10.7118 8.23044 10.732 8.24133 10.7678C8.24444 10.7802 8.24444 10.7942 8.24133 10.8067H8.23978ZM7.62378 6.08245C7.07156 6.08245 6.62356 5.63445 6.62356 5.08223C6.62356 4.53 7.07156 4.082 7.62378 4.082C8.176 4.082 8.624 4.53 8.624 5.08223C8.624 5.63445 8.176 6.08245 7.62378 6.08245Z"
          fill={color ? color : '#000'}
        />
      </g>
      <defs>
        <clipPath id="clip0_594_92">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(0 0.868225)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
