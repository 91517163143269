import React from 'react';

const ManagedRegeneration = () => {
  return (
    <div style={{ display: 'flex', gap: 0 }}>
      {[...Array(4)].map((_, index) => (
        <svg
          key={index}
          viewBox="0 0 8 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.07913 0.0886879L0.460449 2.70386V22.1209H5.68016V2.70386L3.07913 0.0886879ZM3.07913 18.0881C2.85257 18.0881 2.66721 17.9322 2.66721 17.7417C2.66721 17.5512 2.85257 17.3953 3.07913 17.3953C3.30569 17.3953 3.49106 17.5512 3.49106 17.7417C3.49106 17.9322 3.30569 18.0881 3.07913 18.0881ZM3.07913 7.11773C2.85257 7.11773 2.66721 6.96186 2.66721 6.77135C2.66721 6.58084 2.85257 6.42497 3.07913 6.42497C3.30569 6.42497 3.49106 6.58084 3.49106 6.77135C3.49106 6.96186 3.30569 7.11773 3.07913 7.11773Z"
            fill="white"
          />
        </svg>
      ))}
    </div>
  );
};

export default ManagedRegeneration;
