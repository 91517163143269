import React from 'react';

const Agroforestry = () => {
  return (
    <svg viewBox="0 0 37 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.9776 29.3927H32.3283V26.3325H32.9966C35.2075 26.3325 37 24.2972 37 22.3327L35.6643 22.3318C33.938 22.3318 32.4672 23.4238 31.9055 24.9539C31.8301 24.9245 31.7474 24.9071 31.6619 24.9071C31.6619 22.6973 29.8693 20.9064 27.6576 20.9064H25.6555V21.5732C25.6555 23.7829 27.448 25.5739 29.6597 25.5739H30.9945V29.3927H19.3577L19.1563 23.0463L22.2285 18.4835C23.1147 19.0318 24.1589 19.3487 25.2767 19.3487C28.4785 19.3487 31.0745 16.755 31.0745 13.5561C31.0745 10.6135 28.8784 8.18327 26.0333 7.81314V7.79753C26.0333 3.49098 22.5392 0 18.2288 0C13.9184 0 10.61 3.30729 10.4308 7.45495C8.1207 8.03449 6.40904 10.123 6.40904 12.6111C6.40904 15.5464 8.79083 17.927 11.7297 17.927C12.705 17.927 13.6197 17.6643 14.4056 17.206L17.3279 21.0377L17.1588 29.3927H8.04348V25.3746H8.84966C11.5146 25.3746 13.6739 23.2162 13.6739 20.5546V20.5528H12.0634C9.94542 20.5528 8.14827 21.9157 7.49928 23.8114C7.41747 23.7829 7.33014 23.7673 7.23913 23.7673C7.23913 21.1038 5.07888 18.9455 2.41304 18.9455H0V19.7491C0 22.4126 2.16025 24.5709 4.82609 24.5709H6.43478V29.3927H3.49317C3.04917 29.3927 2.68882 29.7528 2.68882 30.1964C2.68882 30.64 3.04917 31 3.49317 31H34.9776C35.4216 31 35.782 30.64 35.782 30.1964C35.782 29.7528 35.4216 29.3927 34.9776 29.3927ZM19.7916 15.4389C20.1409 16.4556 20.765 17.3438 21.5758 18.0161L19.1554 20.6961L18.9826 15.5602C19.2565 15.5344 19.5268 15.4931 19.7916 15.4389ZM15.1273 16.7027C15.586 16.3215 15.9803 15.866 16.2892 15.3526C16.6679 15.449 17.0568 15.5188 17.4548 15.5583L17.3279 19.0566L15.1263 16.7027H15.1273Z"
        fill="white"
      />
    </svg>
  );
};

export default Agroforestry;
